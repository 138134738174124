import React from 'react';
import Grid from '@mui/material/Grid';

import bnChf1 from '../../assets/img/bn_chf1.png';
import bnChf2 from '../../assets/img/bn_chf2.png';

import MapButton from '../../components/MapButton';
import { courseBaseUrl } from '../HomeView';

function CHFListView(){
    const mapUrl = {
        chf1: courseBaseUrl + '/chf/session1/index.html',
        chf2: courseBaseUrl + '/chf/session2/index.html'
    }

    return(
        <div className="home-view">
            <Grid className="map-buttons-wrapper" container spacing={2}>
                <Grid item xs={6}><MapButton url={mapUrl.chf1}><img src={bnChf1} alt="CHF Class 1"/></MapButton></Grid>
                <Grid item xs={6}><MapButton url={mapUrl.chf2}><img src={bnChf2} alt="CHF Class 2"/></MapButton></Grid>
            </Grid>
        </div>
    )
}

export default CHFListView;