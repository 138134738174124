import * as React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import { changePassword, hideChangePasswordModal, logout } from '../../store/actions';
import { connect } from 'react-redux';
import { StoreState } from '../../store/store';

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

interface Props {
  email: string;
  show: boolean;
  changePassword: Function;
  hideChangePasswordModal: Function;
  isTempPassword: boolean;
  logout: Function;
}

const initState = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
  showPassword: false
}

class ChangePasswordModal extends React.Component<Props>{

  state = {...initState}; 

  componentDidMount() {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        if (value !== this.state.newPassword) {
            return false;
        }
        return true;
    });

    ValidatorForm.addValidationRule('passwordLength', (value) => {
      if (value.length < 10) {
          return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule('hasUpperCaseLetter', (value) => {
      if (!value.match(/.*[A-Z].*/)) {
          return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule('hasLowerCaseLetter', (value) => {
      if (!value.match(/.*[a-z].*/)) {
          return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule('hasLowerCaseLetter', (value) => {
      if (!value.match(/.*[a-z].*/)) {
          return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule('hasNumber', (value) => {
      if (!value.match(/.*\d.*/)) {
          return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule('hasSpecialCharacter', (value) => {
      if (!value.match(/.*\W.*/)) {
          return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
      ValidatorForm.removeValidationRule('isPasswordMatch');
      ValidatorForm.removeValidationRule('passwordLength');
      ValidatorForm.removeValidationRule('hasUpperCaseLetter');
      ValidatorForm.removeValidationRule('hasLowerCaseLetter');
      ValidatorForm.removeValidationRule('hasNumber');
      ValidatorForm.removeValidationRule('hasSpecialCharacter');
  }

  handleCancel = () =>{
    if(this.props.isTempPassword){
      this.props.logout();
    }else{
      this.props.hideChangePasswordModal();
    }
  }
  
  handleOldPasswordChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const val = event.currentTarget.value;
    this.setState((state) => ({oldPassword: val}));
  }

  handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>)=>{

    const val = event.currentTarget.value;
    this.setState((state) => ({newPassword: val}));
  }
  
  handleConfirmNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const val = event.currentTarget.value;
    this.setState((state) => ({confirmNewPassword: val}));
  }

  handleClickShowPassword = ()=>{
    this.setState((state) => ({showPassword: !this.state.showPassword}));
  }

  handleSubmit = async () => {

    // event.preventDefault();

    this.props.changePassword(this.state.oldPassword, this.state.newPassword, ()=>{
      this.setState((state) => ({
        ...initState
      }));
    })

  };

  render(){
    return (
      <Modal
        open={this.props.show}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-area change-password-modal">
          <div className="modal-header">
                <h3>Please enter your current and new password.</h3>
          </div>
          <ValidatorForm
              onSubmit={this.handleSubmit}
              onError={errors => console.log(errors)}
            >
            <div className="modal-content change-password-form">
              <TextValidator
                  autoComplete="off"
                  fullWidth
                  id="current-password" 
                  label="Password"
                  onChange={this.handleOldPasswordChange}
                  name="Current Password"
                  type={this.state.showPassword ? 'text' : 'password'}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  value={this.state.oldPassword}
              />
              <TextValidator
                autoComplete="off"
                fullWidth
                id="new-password" 
                label="New Password"
                name="New Password"
                onChange={this.handleNewPasswordChange}
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.newPassword}
                validators={['required', 'passwordLength', 'hasUpperCaseLetter', 'hasLowerCaseLetter', 'hasNumber', 'hasSpecialCharacter']}
                errorMessages={[
                  'this field is required', 
                  'Minimum 10 characters',
                  'Minimum 1 upper case character',
                  'Minimum 1 lower case character',
                  'Minimum 1 number',
                  'Minimum 1 special character'
                ]}
              />
              <TextValidator
                autoComplete="off"
                fullWidth
                id="verify-password"  
                label="Verify New Password"
                name="Verify Password"
                type={this.state.showPassword ? 'text' : 'password'}
                validators={['required', 'isPasswordMatch']}
                value={this.state.confirmNewPassword}
                onChange={this.handleConfirmNewPasswordChange}
                errorMessages={['this field is required', 'password mismatch']}
              />
              {// eslint-disable-next-line
              }<a className="toggleShowHidePassword" onClick={this.handleClickShowPassword}>{this.state.showPassword? "Hide password" : "Show password"}</a>
            </div>
            <Grid className="modal-footer" container spacing={2}>
              <Grid item xs={6}>
                <Button
                    onClick={this.handleCancel}
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 1, mb: 1 }}
                    >
                    Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 1 }}
                    >
                    SET NEW PASSWORD
                </Button>
              </Grid>
            </Grid>
            </ValidatorForm>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state:StoreState)=>{
  
  const show = state.isTempPassword || state.showChangePasswordModal;
  
  return { 
    show: show, 
    isTempPassword: state.isTempPassword,
    email :  state.user.email,
  }
}

export default connect(mapStateToProps, { changePassword, hideChangePasswordModal, logout })(ChangePasswordModal);