import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { reducers } from './reducers';
 
export interface User {
    memberID: string;
    email: string;
    name: string;
    tempPassword: boolean;
}

export interface CurrentError {
    show: boolean;
    message: string;
    logout: boolean;
}

export interface ModalObj {
    show: boolean;
    message: string;
}

export interface ResetPasswordObj {
    token: string;
    cancel: boolean;
    email: string;
}

export interface StoreState {
    user: User;
    showLoadingModal: boolean;
    showChangePasswordModal: boolean;
    showChangeEmailModal: boolean;
    showVerifyCodeModal: boolean;
    error: CurrentError;
    acknowledgeModal: ModalObj;
    resetPassword: ResetPasswordObj;
    userPreferences: string[] | null;
    accessToken: string;
    isTempPassword: boolean;
}

const store = createStore(
    reducers, 
    composeWithDevTools(applyMiddleware(thunk))
);

export default store;