import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';

interface Props{
    url: string;
    useLink?: boolean;
    children?: JSX.Element;
}

function openMap(url:string){
    window!.open(url, '', 'width=1500,height=850')!.focus();
}

function MapButton(props:Props){

    return(
        <Grid item xs={6}>

            { props.useLink ? 
                <Link className="open-map-bn tes2" to={props.url}>{ props.children }</Link>
                :                         
                // eslint-disable-next-line
                <a className="open-map-bn test1" onClick={()=>openMap(props.url)}>{ props.children }</a> 
            }
      
        </Grid>
    )
}

export default MapButton;