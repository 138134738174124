import * as React from 'react';
import type { FC } from 'react';
import Modal from '@mui/material/Modal';
import { connect } from 'react-redux';
import { StoreState } from '../../store/store'
 
interface Props{
    show?: boolean;
}

const LoadingModal:FC<Props> = (props: Props)=>{

  return (
      <Modal 
        className="loading-modal"
        open={props.show ? props.show: false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-area">
          <div className="modal-header">
                <h3>Preparing data...</h3>
          </div>
          <div className="modal-content">
            <div className="loader"></div>
          </div>
        </div>
      </Modal>
  );
}

const mapStateToProps = (state: StoreState) : {show:boolean}=> {
    return { show: state.showLoadingModal};
}

export default connect(mapStateToProps, null)(LoadingModal);