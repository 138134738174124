import React, { useState } from 'react';
import { TextField, Button  } from '@mui/material';
import { StoreState, User } from '../store/store';
import { connect } from 'react-redux';
import { showChangePasswordModal, showChangeEmailModal, changeName } from '../store/actions';

interface Props{
    user: User;
    showChangePasswordModal: Function;
    showChangeEmailModal: Function;
    changeName: Function;
}

function UserProfileView(props: Props){

    const [isEditProfileMode, setEditProfileMode ] = useState(false);

    const [ name, setName ] = useState(props.user.name)

    const showChangePasswordModal = ()=> props.showChangePasswordModal();
    const showChangeEmailModal = ()=> props.showChangeEmailModal();
    const editProfile = ()=> setEditProfileMode(true);
    
    const submitNameChange = ()=> {
        props.changeName(name);
        setEditProfileMode(false);
    }
    
    const handleNameChange = (event:React.ChangeEvent<HTMLInputElement>) =>{
        setName(event.target.value);
    }

    const cancelNameChange = ()=>{
        setName(props.user.name);
        setEditProfileMode(false);
    }

    return (
         <div className="user-profile-view">
             <div className="profile-data">
                <TextField
                    disabled = {!isEditProfileMode}
                    id="name"
                    label="Name"
                    fullWidth
                    value={name}
                    onChange={handleNameChange}
                />
                <TextField
                    disabled
                    fullWidth
                    id="email"
                    label="Email"
                    defaultValue={props.user.email}
                />
                {   !isEditProfileMode && 
                    <>
                        <Button variant="contained" onClick={editProfile} fullWidth>Change Name</Button>
                        <Button variant="contained" onClick={showChangeEmailModal} fullWidth>Change Email</Button>
                        <Button variant="contained" onClick={showChangePasswordModal} fullWidth>Change Password</Button>
                    </>
                }{
                    isEditProfileMode && <>
                        <Button variant="contained" onClick={submitNameChange} fullWidth>Save Name Change</Button>
                        <Button variant="outlined" onClick={cancelNameChange} fullWidth>Cancel</Button>
                    </>
                }

             </div>
         </div>
     )
}

const mapStateToProps = (state:StoreState)=>{
    return { user :  state.user }
}
  
export default connect(mapStateToProps, { showChangePasswordModal, showChangeEmailModal, changeName })(UserProfileView);
