import axios from 'axios';
import { Dispatch } from 'redux';
import { ActionTypes } from './types';
import store, { User } from './store';
import Cookies from 'js-cookie';

export const urls = {
    login: '/auth/login',
    signup: '/auth/signup',
    requestPasswordReset:'/password/reset',
    changePassword:'/password',
    setNewPassword: '/auth/account/password?token=',
    getUserInfo: '/user/current',
    changeName: '/user/name',
    changeEmail: '/user/email-update',
    verifyCode: '/user/email',
    getUserPreferences: 'progress/checked-in',
    updateUserPreferences: 'progress/check-in',
}

interface LoginResponse {
    accessExpiry: number;
    accessToken: string;
    userProfile: User | any;
}

export interface Action {
    type: ActionTypes;
    payload?: any;
}

export interface LoginAction extends Action{
    payload: LoginResponse;
}

export interface SetPermissionAction extends Action{
    payload: {
        english: boolean;
        spanish: boolean;
    }
}

export interface SetResetPasswordTokenAction extends Action {
    payload: {
        token:string;
        cancel: boolean;
        email: string;
    }
}

export const login = (username: string, password: string) =>{
    return (dispatch: Dispatch) =>{

        dispatch<any>(showLoadingSpinner());
        
        var bodyFormData = new FormData();
        bodyFormData.append('username', username);
        bodyFormData.append('password', password);

        const csrfToken = Cookies.get('csrftoken');

        axios({
            method: "post",
            url: urls.login,
            data: bodyFormData,
            headers: {
                "Content-Type": "multipart/form-data",
                "x-csrftoken": `${csrfToken}`
            },
          }
        ).then((res)=>{

            const accessToken = res.data.access_token;

            localStorage.setItem('accessToken', accessToken);

            dispatch<LoginAction>({
                type: ActionTypes.login,
                payload: {
                    accessExpiry: 1111,
                    accessToken: res.data.access_token,
                    userProfile: {
                        tempPassword: true
                    }
                }
            })

            dispatch<any>(getUserInfo());
            dispatch<any>(getUserPreferences());
            
        }).catch((e)=>{
            dispatch<any>(showError('Invalid login credentials, please try again.'));
            console.log('error:', e);
        })
    };
}

const getConfig = ()=>{
    const storeState = store.getState();
    const csrfToken = Cookies.get('csrftoken');
    return {
        headers: {
            Authorization: `Bearer ${storeState.accessToken}`,
            "x-csrftoken": `${csrfToken}`
        }
    }
}

export const logout = ()=>{

    localStorage.clear();

    return (dispatch: Dispatch) =>{
        dispatch<Action>({
            type: ActionTypes.logout
        })
    };
}

export const showLoadingSpinner = ()=>{
	return (dispatch: Dispatch)=>{
        dispatch<Action>({
		    type:ActionTypes.setLoadingModal,
            payload:true
        })
	};
}

export const hideLoadingSpinner = ()=>{
	return (dispatch: Dispatch)=>{
        dispatch<Action>({
		    type:ActionTypes.setLoadingModal,
            payload:false
        })
	};
}

export const showError = (msg: string, forceLogout: boolean = true)=>{

    return (dispatch: Dispatch)=>{
        dispatch<any>(hideLoadingSpinner());
        dispatch<Action>({
		    type:ActionTypes.showError,
            payload: {
                message: msg,
                logout: forceLogout
            }
        })
	};
}

export const hideError = (forceLogout: boolean)=>{

    return (dispatch: Dispatch)=>{
        dispatch<Action>({
		    type:ActionTypes.hideError,
        });

        if(forceLogout) {
            dispatch<any>(logout());
        }
	};
}

export const showAcknowledgeModal = (msg:string)=>{

    return (dispatch: Dispatch)=>{
        dispatch<Action>({
		    type:ActionTypes.showAcknowledgeModal,
            payload: msg
        })
	};
}

export const hideAcknowledgeModalAndLogout = ()=>{

    return (dispatch: Dispatch)=>{
        dispatch<Action>({
		    type:ActionTypes.hideAcknowledgeModalAndLogout,
        });

        dispatch<any>(logout());
	};
}

export const showChangePasswordModal = ()=>{
    return (dispatch: Dispatch)=>{
        dispatch<Action>({
		    type:ActionTypes.showChangePasswordModal,
        })
	};
}

export const showChangeEmailModal = ()=>{
    return (dispatch: Dispatch)=>{
        dispatch<Action>({
		    type:ActionTypes.showChangeEmailModal
        })
	};
}

export const showVerifyCodeModal = ()=>{
    return (dispatch: Dispatch)=>{
        dispatch<Action>({
		    type:ActionTypes.showVerifyCodeModal
        })
	};
}

export const settempPasswordFalse = ()=>{
    return (dispatch: Dispatch)=>{
        dispatch<Action>({
		    type:ActionTypes.settempPasswordFalse,
        })
	};
}

export const hideChangePasswordModal = ()=>{
    return (dispatch: Dispatch)=>{
        dispatch<Action>({
		    type:ActionTypes.hideChangePasswordModal,
        })

        
	};
}

export const hideChangeEmailModal = ()=>{
    return (dispatch: Dispatch)=>{
        dispatch<Action>({
		    type:ActionTypes.hideChangeEmailModal
        })        
	};
}

export const hideVerifyCodeModal = ()=>{
    return (dispatch: Dispatch)=>{
        dispatch<Action>({
		    type:ActionTypes.hideVerifyCodeModal
        })        
	};
}

export const setPasswordResetToken = (token: string, cancel: boolean, email: string) =>{
    return (dispatch: Dispatch)=>{
        dispatch<SetResetPasswordTokenAction>({
		    type:ActionTypes.setPasswordResetToken,
            payload: {
                token: token,
                cancel: cancel,
                email: email
            }
        })
	};
}

export const signup = (email: string, name: string, memberID: string) =>{

    const csrfToken = Cookies.get('csrftoken');

    return (dispatch: Dispatch)=>{

        const successMsg = `Thank you for your registration request. <br>
        Final registration information and a temporary password have been sent to you at: ` + email + `. <br>
        Please follow the directions and complete your registration within 1 hour. <br>
        Once registered, you will be able to access the learning materials on KnowMyHealth website.`
        
        dispatch<any>(showLoadingSpinner());
        axios({
            method: "post",
            url: urls.signup,
            data: {
                email: email,
                name: name,
                insurance_id: memberID,
            },
            headers: {
                "x-csrftoken": `${csrfToken}`
            },
          }).then((res)=>{
            dispatch<any>(showAcknowledgeModal(successMsg))
        }).catch(err=>{
            if(err.response.status === 441){
                dispatch<any>(showError('Error! User with this email has already registered.'));
            }else{
                dispatch<any>(showError('An error occurred when creating your account.'));
            }
        })
        .finally(()=>{
            dispatch<any>(hideLoadingSpinner());
        })
    }
}

export const changePassword = (oldPassword: string, newPassword: string, onSuccess: ()=> void) =>{
    return(dispatch: Dispatch)=>{
        dispatch<any>(showLoadingSpinner());
        axios.put<void>(urls.changePassword, {
            current_password: oldPassword,
            new_password: newPassword
        }, getConfig())
        .then(()=>{
            const storeState = store.getState();
            if(storeState.user.tempPassword){
                dispatch<any>(settempPasswordFalse());
            }
                
            dispatch<any>(showAcknowledgeModal("Password was successfully changed! Please login now with your new password."));
            dispatch<any>(updateIsTempPassword(false));
            onSuccess();
        })
        .catch(e=>{
            dispatch<any>(showError('An error occurred in the change password request.'));
        })
        .finally(()=>{
            dispatch<any>(hideLoadingSpinner());
        })

        dispatch<any>(hideChangePasswordModal());
    }
}

export const changeEmail = (newEmail: string, onSuccess: ()=> void) =>{
    return(dispatch: Dispatch)=>{
        dispatch<any>(showLoadingSpinner());
        axios.post<void>(urls.changeEmail + '?email=' + newEmail, {}, getConfig())
        .then(()=>{
            dispatch<any>(showVerifyCodeModal());
            onSuccess();
            dispatch<any>(hideChangeEmailModal());
        })
        .catch(e=>{
            let msg = e.response.data.detail ? e.response.data.detail : 'An error occurred in change email request: ' + e.response.status;
            dispatch<any>(showError(msg, false));
        })
        .finally(()=>{
            dispatch<any>(hideLoadingSpinner());
        })
    }
}

export const verifyCode = (code: string, onSuccess: ()=> void) =>{
    return(dispatch: Dispatch)=>{
        dispatch<any>(showLoadingSpinner());
        axios.put<void>(urls.verifyCode + '?user_code=' + code, {}, getConfig())
        .then(()=>{
            dispatch<any>(showAcknowledgeModal("Your email address has been successfully changed! Please login now with your new email."));
            onSuccess();
            dispatch<any>(hideVerifyCodeModal());
        })
        .catch(e=>{
            let msg = e.response.data.detail ? e.response.data.detail : 'An error occurred in change email request: ' + e.response.status;
            dispatch<any>(showError(msg, false));
        })
        .finally(()=>{
            dispatch<any>(hideLoadingSpinner());
        })
    }
}

interface GetUserInfoAction extends Action {
    payload: {
        name: string;
        email: string;
        memberID: string;
    }
}

export const getUserInfo = ()=> {
    return (dispatch: Dispatch)=>{
        axios.get(urls.getUserInfo,  getConfig())
        .then((res)=>{
            dispatch<GetUserInfoAction>({
                type: ActionTypes.getUserInfo,
                payload: {
                    name: res.data.name,
                    email: res.data.email,
                    memberID: res.data.insurance_id
                }
            })

            dispatch<any>(updateIsTempPassword(res.data.temp_password));
            dispatch<any>(hideLoadingSpinner());
        })
    }
}

export const updateIsTempPassword = (isTempPassword: boolean) =>{
    return (dispatch: Dispatch) =>{
        dispatch<Action>({
            type: ActionTypes.updateIsTempPassword,
            payload: isTempPassword
        })
    }
}

export const getUserPreferences = ()=> {

    const config = getConfig();
    return (dispatch: Dispatch)=>{
        axios.get(urls.getUserPreferences, config)
        .then((res)=>{
            dispatch<GetUserInfoAction>({
                type: ActionTypes.updateUserPreferences,
                payload: res.data
            })
        })
    }
}

export const conditions = {
    diabetes: 'DIABETES', 
    fall_prevention: 'FALLS_PREVENTION', 
    copd: 'COPD', 
    chf: 'CHF', 
    tobacco: 'TOBACCO_CESSATION', 
    depression: 'DEPRESSION'
}

interface UpdatePreferencesAction extends Action {
    payload: string[]
}

export const updateUserPreferences = (selections: string[], callback:Function)=> {

    let data: {[key:string] : boolean} = {}

    Object.entries(conditions).forEach(([key, value]) =>{
        if(selections.includes(value)){
            data[value] = true;
        }else{
            data[value] = false;
        }

    });

    return (dispatch: Dispatch)=>{
        dispatch<any>(showLoadingSpinner());
        axios.post(urls.updateUserPreferences, {clinical_conditions: data}, getConfig())
        .then(()=>{
            dispatch<UpdatePreferencesAction>({
                type: ActionTypes.updateUserPreferences,
                payload: selections
            })
            callback();
        })
        .finally(()=>{
            dispatch<any>(hideLoadingSpinner());
        })
    }
}


export interface ChangeNameAction extends Action {
    payload: {
        name: string;
    }
}

export const changeName = (name: string,) =>{
    return (dispatch: Dispatch)=>{
        dispatch<any>(showLoadingSpinner());
        axios.patch<ChangeNameAction>(urls.changeName + '?user_name=' + name, {}, getConfig())
        .then(()=>{
            dispatch<Action>({
                type: ActionTypes.updateUserName,
                payload: {
                    name: name,
                }
            })
        })
        .finally(()=>{
            dispatch<any>(hideLoadingSpinner());
        })

    }
}