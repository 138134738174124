import * as React from 'react';
import type { FC } from 'react';
import Modal from '@mui/material/Modal';
import { connect } from 'react-redux';
import { StoreState } from '../../store/store';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { hideError } from '../../store/actions';

interface Props{
    show?: boolean;
    message?: string;
    logout?: boolean;
    hideError: any;
}

const ErrorModal:FC<Props> = (props: Props)=>{
    return (
      <Modal 
        className="error-modal"
        open={props.show ? props.show: false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
            <div className="modal-area">
            <div className="modal-header">
                    <h3>Error</h3>
            </div>
            <div className="modal-content">
                {props.message}
            </div>
            <Grid className="modal-footer" container spacing={2} justifyContent="flex-end">
                <Grid item xs={2}>
                <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 1 }}
                    onClick={() => props.hideError(props.logout)}
                    >
                    OK
                </Button>
                </Grid>
            </Grid>
            </div>
        </Modal>
  );
}

const mapStateToProps = (state: StoreState) : {show:boolean, message: string, logout: boolean}=> {
    return { show: state.error.show, message: state.error.message, logout: state.error.logout};
}

export default connect(mapStateToProps, { hideError })(ErrorModal);