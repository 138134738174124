export enum ActionTypes {
    login,
    logout,
    setLoadingModal,
    showError,
    hideError,
    showAcknowledgeModal,
    hideAcknowledgeModalAndLogout,
    setPasswordResetToken,
    showChangePasswordModal,
    showChangeEmailModal,
    showVerifyCodeModal,
    hideChangePasswordModal,
    hideChangeEmailModal,
    hideVerifyCodeModal,
    settempPasswordFalse,
    getUserInfo,
    updateUserName,
    updateUserPreferences,
    updateIsTempPassword
}