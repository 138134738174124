import React, { useState } from 'react';
import { StoreState } from '../store/store';
import { connect } from 'react-redux';
import { updateUserPreferences, conditions  } from '../store/actions';
import Grid from '@mui/material/Grid';
// import { showChangePasswordModal,  } from '../store/actions';

import bnDiabetes from '../assets/img/preferences_bn/diabetes.png'
import bnFallPrevention from '../assets/img/preferences_bn/falls_prevention.png'
import bnCOPD from '../assets/img/preferences_bn/copd.png'
import bnCHF from '../assets/img/preferences_bn/con_heart_failure.png'
import bnTobacco from '../assets/img/preferences_bn/tobacco_cess.png'
import bnDepression from '../assets/img/preferences_bn/depression.png'

import bnUnSelectDiabetes from '../assets/img/preferences_bn/diabetes_checked.png'
import bnUnSelectFallPrevention from '../assets/img/preferences_bn/falls_prevention_checked.png'
import bnUnSelectCOPD from '../assets/img/preferences_bn/copd_checked.png'
import bnUnSelectCHF from '../assets/img/preferences_bn/con_heart_failure_checked.png'
import bnUnSelectTobacco from '../assets/img/preferences_bn/tobacco_cess_checked.png'
import bnUnSelectDepression from '../assets/img/preferences_bn/depression_checked.png'
import bnSubmit from '../assets/img/preferences_bn/submit_bn.png'

import ConfirmPreferencesChangedModal from '../components/modal/ConfirmPreferencesChanged';
import { Redirect } from 'react-router-dom';
import { routePath } from '../routes';

interface Props{
    userPreferences: string[] | null;
    updateUserPreferences: Function;
}

function UserPreferencesView(props: Props){

    const [ selectedConditions, setSelectedConditions ] = useState(props.userPreferences !== null ? props.userPreferences : []);

    const [ showConfirmMsg, setShowConfirmMsg ] = useState(false);
    const [ changeAcknowledged, setChangeAcknowledged ] = useState(false);

    const addCondition = (condition:string)=>{
        let copySelected = [...selectedConditions];
        if(copySelected.indexOf(condition) === -1){
            copySelected.push(condition);     
            setSelectedConditions(copySelected);
        }
    }

    const removeCondition = (condition: string)=>{
        let copySelected = [...selectedConditions];

        const index = copySelected.indexOf(condition);
        if (index > -1) { 
            copySelected.splice(index, 1); 
            setSelectedConditions(copySelected);
        }
    }

    const submitCondtionsChange = ()=>{
        props.updateUserPreferences(selectedConditions, showConfirmMessage);
    }

    const showConfirmMessage = ()=>{
        setShowConfirmMsg(true);
    }
    
    const acknowledgeChange = ()=>{
        setShowConfirmMsg(false)
        setChangeAcknowledged(true);
    }

    if(changeAcknowledged){
        return <Redirect to={routePath.home} />
    }else{
        return (
            <>
             <div className="user-preferences-view">
                {/* eslint-disable */}
                <Grid container>
                    <Grid item xs={6}> 
                        {!selectedConditions.includes(conditions.diabetes) && <a onClick={()=>addCondition(conditions.diabetes)}><img src={bnDiabetes} alt="select Diabetes"/></a> }
                        {selectedConditions.includes(conditions.diabetes) &&<a onClick={()=>removeCondition(conditions.diabetes)}><img src={bnUnSelectDiabetes} alt="Un-select Diabetes"/></a> }
                    </Grid>
                    <Grid item xs={6}>
                        {!selectedConditions.includes(conditions.fall_prevention) && <a onClick={()=>addCondition(conditions.fall_prevention)}><img src={bnFallPrevention} alt="select Fall Prevention"/></a> }
                        {selectedConditions.includes(conditions.fall_prevention) &&<a onClick={()=>removeCondition(conditions.fall_prevention)}><img src={bnUnSelectFallPrevention} alt="Un-select Fall Prevention"/></a> }
                    </Grid>
                    <Grid item xs={6}>
                        {!selectedConditions.includes(conditions.copd) && <a onClick={()=>addCondition(conditions.copd)}><img src={bnCOPD} alt="select COPD"/></a> }
                        {selectedConditions.includes(conditions.copd) &&<a onClick={()=>removeCondition(conditions.copd)}><img src={bnUnSelectCOPD} alt="Un-select COPD"/></a> }
                    </Grid>
                    <Grid item xs={6}>
                        {!selectedConditions.includes(conditions.chf) && <a onClick={()=>addCondition(conditions.chf)}><img src={bnCHF} alt="select CHF"/></a> }
                        {selectedConditions.includes(conditions.chf) &&<a onClick={()=>removeCondition(conditions.chf)}><img src={bnUnSelectCHF} alt="Un-select CHF"/></a> }
                    </Grid>
                    <Grid item xs={6}> 
                        {!selectedConditions.includes(conditions.tobacco) && <a onClick={()=>addCondition(conditions.tobacco)}><img src={bnTobacco} alt="select Tobacco Cessation"/></a> }
                        {selectedConditions.includes(conditions.tobacco) &&<a onClick={()=>removeCondition(conditions.tobacco)}><img src={bnUnSelectTobacco} alt="Un-select Tobacco Cessation"/></a> }
                    </Grid>
                    <Grid item xs={6}>
                        {!selectedConditions.includes(conditions.depression) && <a onClick={()=>addCondition(conditions.depression)}><img src={bnDepression} alt="select Depression"/></a> }
                        {selectedConditions.includes(conditions.depression) &&<a onClick={()=>removeCondition(conditions.depression)}><img src={bnUnSelectDepression} alt="Un-select Depression"/></a> }
                    </Grid>
                </Grid>
                <Grid className="submit-bn-row">
                    <a onClick={submitCondtionsChange}><img src={bnSubmit}/></a>
                </Grid>
                {/* eslint-enable */}
             </div>
             <ConfirmPreferencesChangedModal show={showConfirmMsg} action={acknowledgeChange} />
            </>
         )
    }

}

const mapStateToProps = (state:StoreState)=>{
    return { userPreferences :  state.userPreferences }
}
  
export default connect(mapStateToProps, { updateUserPreferences })(UserPreferencesView);