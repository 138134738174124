import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import routes, { renderRoutes } from './routes';
// import ErrorBoundary from './components/helper/ErrorBoundary';
import LoadingModal from './components/modal/Loading';
import ErrorModal from './components/modal/Error';
import AcknowledgeModal from './components/modal/Acknowledge';
import ChangePasswordModal from './components/modal/ChangePassword';
import ChangeEmailModal from './components/modal/ChangeEmail';
import VerifyCodeModal from './components/modal/VerifyCode';

const App = () : JSX.Element =>{
  return (
    <div className="App">
      {/* <ErrorBoundary> */}
        <BrowserRouter basename='/'>
          {renderRoutes(routes)}
        </BrowserRouter>
      <LoadingModal /> 
      {/* </ErrorBoundary> */}
      <ErrorModal /> 
      <ChangePasswordModal />
      <ChangeEmailModal />
      <VerifyCodeModal />
      <AcknowledgeModal />
    </div>
  );
}


export default App;