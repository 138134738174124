import React from 'react';
import Grid from '@mui/material/Grid';

import bnSession1 from '../../assets/img/bn_tobacco1.png';
import bnSession2 from '../../assets/img/bn_tobacco2.png';
import bnSession3 from '../../assets/img/bn_tobacco3.png';
import bnSession4 from '../../assets/img/bn_tobacco4.png';
import bnSession5 from '../../assets/img/bn_tobacco5.png';
import bnSession6 from '../../assets/img/bn_tobacco6.png';

import MapButton from '../../components/MapButton';
import { courseBaseUrl } from '../HomeView';

function TobaccoCessationListView(){
    const mapUrl = {
        session1: courseBaseUrl + '/smokingcessation/session1/index.html',
        session2: courseBaseUrl + '/smokingcessation/session2/index.html',
        session3: courseBaseUrl + '/smokingcessation/session3/index.html',
        session4: courseBaseUrl + '/smokingcessation/session4/index.html',
        session5: courseBaseUrl + '/smokingcessation/session5/index.html',
        session6: courseBaseUrl + '/smokingcessation/session6/index.html'
    }

    return(
        <div className="home-view">
            <Grid className="map-buttons-wrapper" container spacing={2}>
                <Grid item xs={6} className="session-title">Choosing to Quit: My Plan for Health</Grid>
                <Grid item xs={6} className="session-title">Choosing to Quit: Planning Ahead</Grid>
                <MapButton url={mapUrl.session1}><img src={bnSession1} alt="Tobacco Cessation Class 1" /></MapButton>
                <MapButton url={mapUrl.session4}><img src={bnSession4} alt="Tobacco Cessation Class 2" /></MapButton>
                <MapButton url={mapUrl.session2}><img src={bnSession2} alt="Tobacco Cessation Class 3" /></MapButton>
                <MapButton url={mapUrl.session5}><img src={bnSession5} alt="Tobacco Cessation Class 4" /></MapButton>
                <MapButton url={mapUrl.session3}><img src={bnSession3} alt="Tobacco Cessation Class 5" /></MapButton>
                <MapButton url={mapUrl.session6}><img src={bnSession6} alt="Tobacco Cessation Class 6" /></MapButton>
            </Grid>
        </div> 
    )
}

export default TobaccoCessationListView;