import * as React from 'react';
import { TextField, Button, Modal, Grid } from '@mui/material';
import { verifyCode, hideVerifyCodeModal } from '../../store/actions';
import { connect } from 'react-redux';
import { StoreState } from '../../store/store';


interface Props {
  show: boolean;
  verifyCode: Function;
  hideVerifyCodeModal: Function;
}

const initState = {
  code: '',
  newEmail: ''
}

class VerifyCodeModal extends React.Component<Props>{

  state = {...initState};

  handleClose = () => this.props.hideVerifyCodeModal();

  handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const val = event.currentTarget.value;
    this.setState(() => ({code: val}));
  }

  handleSubmit = async () => {
    this.props.verifyCode(this.state.code, ()=>{
      this.setState(() => ({
        ...initState
      }));
    })
  };

  render(){
    return (
      <Modal
        open={this.props.show}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-area">
          <div className="modal-header">
                <h3>Verification code has been sent to your new email address. Please enter this code and click CONFIRM button.</h3>
          </div>
          <div className="modal-content">
              <TextField
                id="outlined"
                label="Verification code"
                fullWidth
                onChange={this.handleCodeChange}
              />
          </div>
          <Grid className="modal-footer" container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={this.handleClose}
                fullWidth
                variant="outlined"
                sx={{ mt: 1, mb: 1 }}
              >
              CANCEL
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                onClick={this.handleSubmit}
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 1 }}
              >
              CONFIRM EMAIL CHANGE
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state:StoreState)=>{
  return { 
    show: state.showVerifyCodeModal
  }
}

export default connect(mapStateToProps, { verifyCode, hideVerifyCodeModal  })(VerifyCodeModal);
