import * as React from 'react';
import type { FC } from 'react';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

interface Props{
    show?: boolean;
    action: any;
}

const ConfirmPreferencesChangedModal:FC<Props> = (props: Props)=>{

    return (
      <Modal 
        className="error-modal acknowledge-modal"
        open={props.show ? props.show: false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
            <div className="modal-area">
            <div className="modal-header">
                    <h3>Confirmation</h3>
            </div>
            <div className="modal-content">
                You have successfully updated your course preferences.
            </div>
            <Grid className="modal-footer" container spacing={2} justifyContent="flex-end">
                <Grid item xs={2}>
                <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 1 }}
                    onClick={props.action}
                    >
                    OK
                </Button>
                </Grid>
            </Grid>
            </div>
        </Modal>
  );
}

export default ConfirmPreferencesChangedModal;