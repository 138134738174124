import React from 'react';
import type { FC, ReactNode } from 'react';
import Topbar  from './Topbar';
import { useLocation  } from 'react-router-dom'
import { routePath } from '../../routes';

interface Props {
    children?: ReactNode;
}

const AppLayout: FC<Props> = (props:Props) =>{

    let isSessionMapView = false;
    let showTopBar = true;

    const location = useLocation();

    if(location.pathname.includes('/maps') || location.pathname.includes('/spanishmaps')){
        isSessionMapView = true;
    }

    if(location.pathname.includes(routePath.signup) || location.pathname.includes(routePath.login)){
        showTopBar = false;
    }

    return (
        <div className={`${ isSessionMapView ? "showingMap" : ""}`}> 
            { showTopBar && <Topbar isSessionMapView={isSessionMapView} /> }
            <div className={`main-content`}>
                { props.children }
            </div>
            <div className={`footer-area`}>
                <p className="copyright muted">
                    Copyright {new Date().getFullYear()} | VillageMD Healthy Interactions, LLC
                </p>            
            </div>
        </div>
    )
}



export default AppLayout;