import React from 'react';
import type { FC } from 'react';

import { AppBar, Toolbar, Box } from '@mui/material'
// import Logo from './Logo'

import { logout } from '../../store/actions';
import { connect } from 'react-redux';
import { User, StoreState } from '../../store/store'
import { Link } from 'react-router-dom'
import { routePath } from '../../routes'

interface Props{
    user: User;
    logout: any;
    isSessionMapView: boolean;
}

const Topbar: FC<Props> = (props: Props)=>{
    return (
        <div className={`nav-bar-wrapper`}>
            <AppBar sx={{ background: 'white'}} position="fixed" className={`top-nav-bar`}>
                <Toolbar>
                    {/* <Box><Link to={routePath.home}><Logo /></Link></Box>  */}
                    <Box className="header-title" sx={{
                        color: 'black',
                        marginLeft: '10px'
                    }}><Link to={routePath.home}>Healthy Interactions Conversation Map Learning Tools (COLTs)</Link></Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box className="welcome right-separator">
                        <p>Welcome, {props.user.name.split(' ').shift()} </p>
                    </Box>
                    <Box sx={{md: 'flex'}} className="nav-bar-links right-separator">
                        <Link to={routePath.home}>Home</Link>
                    </Box>
                    <Box sx={{md: 'flex'}} className="nav-bar-links right-separator">
                        <Link to={routePath.preferences}>Preferences</Link>
                    </Box>
                    <Box sx={{md: 'flex'}} className="nav-bar-links right-separator">
                        <Link to={routePath.profile}>Your Account</Link>
                    </Box>
                    <Box sx={{md: 'flex'}} className="nav-bar-links right-separator">
                        {// eslint-disable-next-line
                        }<a onClick={props.logout}>Logout</a>
                    </Box>
                </Toolbar>
            </AppBar>
        </div>
    )
}

const mapStateToProps = (state: StoreState) : {user: User}=>{
    return {user: state.user};
}
export default connect(mapStateToProps, { logout })(Topbar);