import { Redirect } from 'react-router-dom';
import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../store/store';

interface Props {
    children?: ReactNode; 
    isAuthenticated?: boolean;
}

const SendToDashboard = ( props: Props )=> {
    if(props.isAuthenticated){
        return <Redirect to={{pathname:"/home"}} />
    }else{
        return(<>{ props.children }</>)
    }
}

const mapStateToProps = (state:StoreState)=>{
    return { isAuthenticated: state.user.email.length > 0}
}

export default connect(mapStateToProps, null)(SendToDashboard);