import * as React from 'react';
import type { FC } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { urls, showError } from '../../store/actions';
import { connect } from 'react-redux';


interface Props {
  showError: Function;
}

const ForgetPasswordModal: FC<Props> = (props:Props)=>{
  const [open, setOpen] = React.useState(false);
  const [email, setEmail]  = React.useState('')
  const [insuranceId, setInsuranceID]  = React.useState('')
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
    setEmail(event.currentTarget.value);
  }

  const handleInsuranceIDChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
    setInsuranceID(event.currentTarget.value);
  }
    
  const handleSubmit = async () => {

    if(email.length === 0 || insuranceId.length === 0){
      alert("Please provide a valid email and member ID") 
    }

    try{
      await axios.post<void>(urls.requestPasswordReset, {email: email, insurance: insuranceId});
    }catch(e){
      console.error("An error occurred in the reset password request.")
    }

    alert("If this email belongs to a valid account, you will receive an email with instructions to change your password.\n\nIf you do not receive an email, please try again later.")
    setEmail('')
    setOpen(false);
  };

  return (
    <div className="forget-password-modal">
      {// eslint-disable-next-line
      }<a className="btn-link" onClick={handleOpen}>Forgot your password?</a>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-area">
          <div className="modal-header">
                <h3>Please enter your email to confirm password reset.</h3>
          </div>
          <div className="modal-content">
            <p>
                  <TextField
                      id="outlined-password-input"
                      label="Email Address"
                      type="Text"
                      value={email}
                      autoComplete="Email"
                      onChange={handleEmailInputChange}
                  />
            </p>
            <p>
                  <TextField
                      label="Member ID"
                      type="Text"
                      value={insuranceId}
                      autoComplete="Member ID"
                      onChange={handleInsuranceIDChange}
                  />
            </p>
          </div>
          <Grid className="modal-footer" container spacing={2}>
            <Grid item xs={6}>
              <Button
                  onClick={handleClose}
                  type="submit"
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 1, mb: 1 }}
                  >
                  Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 1 }}
                  onClick={(event: React.MouseEvent<HTMLElement>)=>handleSubmit()}
                  >
                  Send Reset Request
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}

export default connect(null, { showError })(ForgetPasswordModal);