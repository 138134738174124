import { Redirect } from 'react-router-dom';
import { ReactNode, FC } from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../store/store';
import { routePath } from '../../routes';
import AuthRequired from './AuthRequired';

interface Props {
    children?: ReactNode; 
    userPreferences?: string[] | null;
}

const SendToPreferences:FC<Props> = (props:Props) =>{

    if(props.userPreferences===null){//waiting
        return <AuthRequired>Loading your course preferences...</AuthRequired>
    }else if(props.userPreferences?.length === 0){
        return <Redirect to={routePath.preferences} />
    }else {
        return(<AuthRequired>{ props.children }</AuthRequired>)
    }
}

const mapStateToProps = (state:StoreState)=>{
    return { userPreferences: state.userPreferences }
}

export default connect(mapStateToProps, null)(SendToPreferences);