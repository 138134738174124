import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import LoginView from './views/LoginView';
import SignupView from './views/SignupView';
import HomeView  from './views/HomeView';
import ChfListingView from './views/maps/CHF';
import TobaccoCessationListView from './views/maps/TobaccoCessation';
import UserProfileView from './views/UserProfile';
import UserPreferencesView from './views/UserPreferences';

import AuthRequired from './components/gate/AuthRequired';
import SendToDashboard from './components/gate/SendToDashboard';
import SendToPreferences from './components/gate/SendToPreferences';
import AppLayout from './components/layout/AppLayout';

type Routes = {
    exact?: boolean;
    path: string | string[];
    component: any;
    gate?: any;
    layout?: any;
}[];

export const routePath = {
    login: '/login',
    signup: '/signup',
    home: '/home',
    chf: '/chf',
    tobacco: '/tobaccocessation',
    profile: '/profile',
    preferences: '/preferences'
}

export const renderRoutes = (routes: Routes =[]): JSX.Element =>(
    
    <Switch>
    {
        routes.map((route, i)=>{
            
            const Component = route.component;
            const Gate = route.gate || Fragment;
            const Layout = route.layout || Fragment;

            return(
                <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    render={(props)=>(
                        <Gate>
                            <Layout>
                                <Component {...props} />
                            </Layout>
                        </Gate>
                    )}
                />
            )
        })
    }
    </Switch>
);

const routes:Routes = [
    {
        path: routePath.login,
        exact: true,
        gate: SendToDashboard,
        layout: AppLayout,
        component: LoginView
    },{
            path: routePath.signup,
            exact: true,
            layout: AppLayout,
            component: SignupView
    },{
        path: routePath.home,
        gate: SendToPreferences,
        layout: AppLayout,
        component: HomeView
    },{
        path: routePath.chf,
        gate: AuthRequired,
        layout: AppLayout,
        component: ChfListingView
    },{
        path: routePath.tobacco,
        gate: AuthRequired,
        layout: AppLayout,
        component: TobaccoCessationListView
    },{
        path: routePath.profile,
        gate: AuthRequired,
        layout: AppLayout,
        component: UserProfileView
    },{
        path: routePath.preferences,
        gate: AuthRequired,
        layout: AppLayout,
        component: UserPreferencesView
    },{
        path: '*',
        gate: AuthRequired,
        component: HomeView
    }
]

export default routes;