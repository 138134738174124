import React from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { signup } from '../store/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { routePath } from '../routes';

const theme = createTheme();

interface Props {
  signup: Function;
}

function SignupView(props:Props) {

  const [lastTrapped, setLastTrapped] = React.useState(0);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if((Date.now() - lastTrapped) < 5 * 60 * 1000 || data.get('memberID')){
        setLastTrapped(Date.now());
        alert('Something went wrong, please wait 5 minutes and try again');
      }else if(!data.get('email') || !data.get('name') || !data.get('memberID2')){
        alert('Please fill out all required fields');
      }else{
        props.signup(data.get('email'), data.get('name'), data.get('memberID2'));
      }
  };

  return (
    <ThemeProvider theme={theme}>
          <Grid container spacing={2} className="login-main-grid signup-view">
            <Grid item xs={3} className="header-row"></Grid>
            <Grid item xs={6} className="header-row"><h1>Welcome to KnowMyHealth.com</h1></Grid>
            <Grid item xs={3} className="header-row"></Grid>
            <Grid item xs={3} className="main-area"></Grid>
            <Grid item xs={6} className="main-area">
              <Container component="main" maxWidth="xs" className="login-view">
              <CssBaseline />
              <Box
                className="login-form"
                sx={{
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',  
                  alignItems: 'center',
                }}
              >
                <Box component="form" className="signup-form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                  <h2>CREATE A NEW ACCOUNT</h2>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    placeholder="Email Adress"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    placeholder="Name"
                  />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="memberID"
                    name="memberID"
                    autoComplete="memberID"
                    autoFocus
                    placeholder="Member ID"
                  />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="memberID2"
                    name="memberID2"
                    autoComplete="memberID"
                    autoFocus
                    placeholder="Member ID"
                  />
                <Button
                    className="login-bn"
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                > Signup</Button>
                <Grid container className="agreement-desc">
                    <Grid item xs>
                        Already have an account? Click <Link to={routePath.login}> here</Link> to login instead
                    </Grid>
                </Grid>
                </Box>
              </Box>
            </Container>
          </Grid>
        </Grid>
    </ThemeProvider>
  );
}

export default connect(null, {signup})(SignupView)
 