import { Redirect } from 'react-router-dom';
import { ReactNode, FC } from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../store/store';
import { setPasswordResetToken } from '../../store/actions';

interface Props {
    children?: ReactNode; 
    isAuthenticated?: boolean;
    setPasswordResetToken: any;
}

const getUrlParam = (name:string, params:string[]) =>{

    for(let i=0; i<params.length; i++){
        
        let param = (params[i]).split("=");

        if(name===param[0]){
            return param[1];
        }
    }

    return '';
}

const RequireAuth:FC<Props> = ( props:Props) =>{

    let url = window.location.href;

    if(url.includes('changepasswordpage')){

        let params= url.split('?')[1];
        
        let email = '';
        let cancel =  false;
        let token = getUrlParam('token', params.split('&'));
        
        let isCancel = getUrlParam('cancel', params.split('&'));

        if(isCancel === 'true'){
            email = getUrlParam('email', params.split('&'));
            cancel = true;
            console.log('parse result token: ', token, 'cancel: ', cancel, ' emaill: ', email);

            props.setPasswordResetToken(token, cancel, email);
            return <Redirect to={{pathname:"/cancelchangepassword"}} />

        }else{
            props.setPasswordResetToken(token, cancel, email);
            return <Redirect to={{pathname:"/setpassword"}} />
        }
        
    }else if(!props.isAuthenticated){
        return <Redirect to={{pathname:"/login"}} />
    }

    return (<>{ props.children }</>)

}

const mapStateToProps = (state:StoreState)=>{
    return { isAuthenticated: state.user.email.length > 0 }
}

export default connect(mapStateToProps, { setPasswordResetToken })(RequireAuth);