import React from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ForgetPasswordModal from '../components/modal/ForgetPassword';

import { login } from '../store/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { routePath } from '../routes';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const theme = createTheme();

interface Props {
  login: Function;
}

function LoginView(props:Props) {
  
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    props.login(data.get('email'), data.get('password'));
  };

  return (
    <ThemeProvider theme={theme}>
          <Grid container spacing={2} className="login-main-grid">
            <Grid item xs={2} className="header-row"></Grid>
            <Grid item xs={8} className="header-row"><h1>Welcome to KnowMyHealth.com</h1></Grid>
            <Grid item xs={2} className="header-row"></Grid>
            <Grid item xs={3} className="main-area"></Grid>
            <Grid item xs={6} className="main-area">
              <Container component="main" maxWidth="xs" className="login-view">
              <CssBaseline />
              <Box
                className="login-form"
                sx={{
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',  
                  alignItems: 'center',
                }}
              >
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                  <h2>LOG IN</h2>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    placeholder="Email Adress"
                  />
                  <OutlinedInput
                    className="login-password"
                    required
                    fullWidth
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    placeholder="Password"
                    autoComplete="current-password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Grid container className="forget-password-box">
                      <ForgetPasswordModal />
                  </Grid>
                  <Button
                    className="login-bn"
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Log In
                  </Button>
                  <Grid container className="agreement-desc" sx={{ color: 'text.secondary' }}>
                      By logging in, you agree to Healthy Interactions' Terms of Use and Privacy Policy.
                  </Grid>
                  <Grid container className="signup-bn-wrapper">
                    <Link to={routePath.signup}>Create an Account</Link>
                  </Grid>
                </Box>
              </Box>
            </Container>
          </Grid>
        </Grid>
    </ThemeProvider>
  );
}

export default connect(null, {login})(LoginView);