import * as React from 'react';
import { Button, Modal, Grid } from '@mui/material';
import { changeEmail, hideChangeEmailModal } from '../../store/actions';
import { connect } from 'react-redux';
import { StoreState } from '../../store/store';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';


interface Props {
  email: string;
  show: boolean;
  changeEmail: Function;
  hideChangeEmailModal: Function;
}

const initState = {
  newEmail: ''
}

class ChangeEmailModal extends React.Component<Props>{

  state = {...initState};

  componentDidMount() {
    ValidatorForm.addValidationRule('isValidEmail', (value) => {
      if (!value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
          return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('isValidEmail');
  }

  handleClose = () => this.props.hideChangeEmailModal();

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const val = event.currentTarget.value;
    this.setState(() => ({newEmail: val}));
  }

  handleSubmit = async () => {
    this.props.changeEmail(this.state.newEmail, ()=>{
      this.setState(() => ({
        ...initState
      }));
    })
  };

  render(){
    return (
      <Modal
        open={this.props.show}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-area">
          <div className="modal-header">
                <h3>Please enter your new email address and click SEND CODE button.</h3>
          </div>
          <ValidatorForm
              onSubmit={this.handleSubmit}
              onError={errors => console.log(errors)}
          >
            <div className="modal-content">
                <TextValidator
                  autoComplete="off"
                  fullWidth
                  id="new-email" 
                  label="New email"
                  name="New Email"
                  onChange={this.handleEmailChange}
                  type="email"
                  value={this.state.newEmail}
                  validators={['required', 'isValidEmail']}
                  errorMessages={['This field is required', 'Invalid email format']}
                />
            </div>
            <Grid className="modal-footer" container spacing={2}>
              <Grid item xs={6}>
                <Button
                  onClick={this.handleClose}
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 1, mb: 1 }}
                >
                CANCEL
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 1 }}
                >
                SEND CODE
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state:StoreState)=>{
  return { 
    show: state.showChangeEmailModal, 
    email :  state.user.email
  }
}

export default connect(mapStateToProps, { changeEmail, hideChangeEmailModal  })(ChangeEmailModal);
