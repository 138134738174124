import React from 'react';
import { StoreState } from '../store/store';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import bnDiabetes from '../assets/img/bn_diabetes.png';
import bnFallPrevention from '../assets/img/bn_falls_prevention.png';
import bnCOPD from '../assets/img/bn_copd.png';
import bnCHF from '../assets/img/bn_con_heart_failure.png';
import bnTobacco from '../assets/img/bn_tobacco_cess.png';
import bnDepression from '../assets/img/bn_depression.png';
import { Link } from 'react-router-dom';
import { conditions } from '../store/actions';


import MapButton from '../components/MapButton';
import { routePath } from '../routes';

var baseUrl = window.location.host + '/static';
if(window.location.host === 'localhost:3000'){
    baseUrl = window.location.host
}

export const courseBaseUrl = window.location.protocol + '//' + baseUrl + '/courses';

interface Props {
    userPreferences: string[];
}

function HomeView(props: Props){
    const mapUrl = {
        diabetes: courseBaseUrl + '/diabetes/index.html',
        fall_prevention: courseBaseUrl + '/falls_prevention/index.html',
        copd: courseBaseUrl + '/copd/index.html',
        chf: '/chf',
        depression: courseBaseUrl + '/depression/index.html',
    }

    const selectedConditions = props.userPreferences;

    return( 
        <div className="home-view">
            <h1>Start learning more about:</h1>
            <Grid className="map-buttons-wrapper" container spacing={2}>                
                {selectedConditions.includes(conditions.diabetes) && <Grid item xs={6}><MapButton url={mapUrl.diabetes + '?id=userID'}><img src={bnDiabetes} alt="diabetes map" /></MapButton> </Grid> }
                {selectedConditions.includes(conditions.fall_prevention) && <Grid item xs={6}><MapButton url={mapUrl.fall_prevention}><img src={bnFallPrevention} alt="fall prevention map"/></MapButton> </Grid> }
                {selectedConditions.includes(conditions.copd) && <Grid item xs={6}><MapButton url={mapUrl.copd}><img src={bnCOPD} alt="copd map" /></MapButton> </Grid> }
                {selectedConditions.includes(conditions.chf) && <Grid item xs={6}><Link to={routePath.chf}><img src={bnCHF} alt="chf map"/></Link> </Grid> }
                {selectedConditions.includes(conditions.tobacco) && <Grid item xs={6}><Link to={routePath.tobacco}><img src={bnTobacco} alt="tobacco cessation map"/></Link> </Grid> }
                {selectedConditions.includes(conditions.depression) && <Grid item xs={6}><MapButton url={mapUrl.depression}><img src={bnDepression} alt="depression map" /></MapButton> </Grid> }
            </Grid>
        </div> 
    )
}

const mapStateToProps = (state:StoreState)=>{
    return { userPreferences :  state.userPreferences }
}

export default connect(mapStateToProps, null)(HomeView);


