import { combineReducers } from 'redux';
import { Action } from './actions';    
import { ActionTypes } from './types';
import { StoreState, User, CurrentError, ModalObj, ResetPasswordObj } from './store';


export const initState: StoreState = {
    user: {
        memberID: '',
        email: '',
        name: '',
        tempPassword: false
    },
    showLoadingModal:false,
    showChangePasswordModal: false,
    showChangeEmailModal: false,
    showVerifyCodeModal: false,
    error: {
        show: false,
        message: '',
        logout: false
    },
    acknowledgeModal:{
        show: false,
        message: ''
    },
    resetPassword:{
        token: '',
        cancel: false,
        email: ''
    },
    userPreferences: null,
    accessToken: '',
    isTempPassword: false
}

const userReducer = (state: User = {...initState.user}, action: Action) =>{
    switch(action.type){
        case ActionTypes.logout:
            return {...initState.user};


        case ActionTypes.settempPasswordFalse:
            return {
                ...state,
                tempPassword: false
            }

        case ActionTypes.getUserInfo: {
            return {
                ...state,
                memberID: action.payload.memberID,
                email: action.payload.email,
                name: action.payload.name,
            }
        }

        case ActionTypes.updateUserName:
            return {
                ...state,
                name: action.payload.name,
            }

        default:
            return state;
    }
};

const loadingModalReducer = (state: boolean = initState.showLoadingModal, action: Action) =>{
    switch(action.type){
        case ActionTypes.setLoadingModal:
            return action.payload;

        default:
            return state;
    }
};

const errorReducer = (state: CurrentError = {...initState.error}, action: Action) =>{

    switch(action.type){
        case ActionTypes.showError:
            return {
                show:true,
                message: action.payload.message,
                logout: action.payload.logout
            };

        case ActionTypes.hideError:
            return {
                show: false,
                message: '',
                logout: false
            }

        default:
            return state;
    }
}

const acknowledgeModalReducer = (state: ModalObj = {...initState.acknowledgeModal}, action: Action) =>{

    switch(action.type){
        case ActionTypes.showAcknowledgeModal:
            return {
                show:true,
                message: action.payload
            };

        case ActionTypes.hideAcknowledgeModalAndLogout:
            return {
                show: false,
                message: ''
            }

        default:
            return state;
    }
}

const changePasswordModalReducer = (state: boolean = initState.showChangePasswordModal, action: Action) =>{
    switch(action.type){
        case ActionTypes.showChangePasswordModal:
            return true;
        case ActionTypes.hideChangePasswordModal:
            return false;
        default:
            return state;
    }
}

const changeEmailModalReducer = (state: boolean = initState.showChangeEmailModal, action: Action) =>{
    switch(action.type){
        case ActionTypes.showChangeEmailModal:
            return true;
        case ActionTypes.hideChangeEmailModal:
            return false;
        default:
            return state;
    }
}

const verifyCodeModalReducer = (state: boolean = initState.showVerifyCodeModal, action: Action) =>{
    switch(action.type){
        case ActionTypes.showVerifyCodeModal:
            return true;
        case ActionTypes.hideVerifyCodeModal:
            return false;
        default:
            return state;
    }
}

const resetPasswordReducer = (state: ResetPasswordObj = {...initState.resetPassword}, action: Action)=>{
    switch(action.type){
        case ActionTypes.setPasswordResetToken:
            return {
                token: action.payload.token,
                cancel: action.payload.cancel,
                email: action.payload.email
            }

        case ActionTypes.logout:
            return {...initState.resetPassword}

        default:
            return state;
    }
}

const userPreferencesReducer = (state: string[] | null = null, action: Action)=>{

    switch(action.type){

        case ActionTypes.updateUserPreferences:
            return action.payload;

        case ActionTypes.logout:
            return null;

        default:
            return state;
    }
}

const accessTokenReducer = (state: string = initState.accessToken, action: Action)=>{

    switch(action.type){

        case ActionTypes.login:
            return action.payload.accessToken;

        case ActionTypes.logout:
            return initState.accessToken;
        default:
            return state;
    }
}

const isTempPasswordReducer = (state: boolean = initState.isTempPassword, action: Action)=>{

    switch(action.type){
        case ActionTypes.updateIsTempPassword:
            return action.payload;

        case ActionTypes.logout:
            return initState.isTempPassword;    

        default:
            return state;
    }
}

export const reducers = combineReducers<StoreState>({
    user: userReducer,
    showLoadingModal: loadingModalReducer,
    showChangePasswordModal: changePasswordModalReducer,
    showChangeEmailModal: changeEmailModalReducer,
    showVerifyCodeModal: verifyCodeModalReducer,
    error: errorReducer,
    acknowledgeModal: acknowledgeModalReducer,
    resetPassword: resetPasswordReducer,
    userPreferences: userPreferencesReducer,
    accessToken: accessTokenReducer,
    isTempPassword: isTempPasswordReducer
})