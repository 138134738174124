import * as React from 'react';
import type { FC } from 'react';
import Modal from '@mui/material/Modal';
import { connect } from 'react-redux';
import { StoreState } from '../../store/store';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { hideAcknowledgeModalAndLogout } from '../../store/actions';


interface Props{
    show?: boolean;
    message?: string;
    hideAcknowledgeModalAndLogout: any;
}

const AcknowledgeModal:FC<Props> = (props: Props)=>{

    let msg_array: string[] = [];

    if(props.message){
        msg_array = props.message.split('<br>');
    }
    
    const msg = msg_array.map((str) => <p key={str}>{str}</p>);

    return (
      <Modal 
        className="error-modal acknowledge-modal"
        open={props.show ? props.show: false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
            <div className="modal-area">
            <div className="modal-header">
                    <h3>Confirmation</h3>
            </div>
            <div className="modal-content">
                {msg}
            </div>
            <Grid className="modal-footer" container spacing={2} justifyContent="flex-end">
                <Grid item xs={2}>
                <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 1 }}
                    onClick={props.hideAcknowledgeModalAndLogout}
                    >
                    OK
                </Button>
                </Grid>
            </Grid>
            </div>
        </Modal>
  );
}

const mapStateToProps = (state: StoreState) : {show:boolean, message: string}=> {
    return { show: state.acknowledgeModal.show, message: state.acknowledgeModal.message};
}

export default connect(mapStateToProps, { hideAcknowledgeModalAndLogout })(AcknowledgeModal);